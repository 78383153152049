<template>
    <v-container fluid>
        <v-row>
            <v-container fluid>
                <v-card>
                    <v-card-text>
                        <v-row align="center" justify="center">
                            <v-col cols="12" sm="6">
                                <v-row class="grey lighten-4" align="center" justify="center">
                                    <v-col cols="12"> <strong>FILTROS</strong></v-col>
                                    <v-col cols="12" sm="6">
                                        <v-menu
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="auto"
                                        >
                                            <template #activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="fechaFiltro"
                                                    hide-details="auto"
                                                    dense
                                                    label="FILTRAR POR MES"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                />
                                            </template>
                                            <v-date-picker v-model="fechaFiltro" type="month" no-title scrollable>
                                                <v-spacer />
                                                <v-btn text color="primary" @click="menu = false"> CERRAR </v-btn>
                                                <v-btn color="primary" @click="fnHandleGet"> BUSCAR </v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-divider inset vertical></v-divider>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-row>
                                    <v-col cols="12"> <strong>ACTIONS</strong></v-col>

                                    <v-col cols="12" align="start" justify="center">
                                        <v-btn class="mx-1" small color="green" dark @click="downloadHandle">
                                            DESCARGAR <v-icon right dark> mdi-arrow-down-box </v-icon>
                                        </v-btn>
                                        <v-btn class="mx-1" small color="primary" dark @click="openForm(1)"> CREAR </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-container>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title dark>
                        <strong>USUARIOS</strong>
                        <v-spacer />
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="BUSCAR" single-line hide-details />
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            item-key="id"
                            flat
                            :headers="headers"
                            :items="lista"
                            :search="search"
                            :loading="loading"
                            :sort-by="['fechaCreacion']"
                            :sort-desc="[true]"
                            loading-text="...Cargando"
                        >
                            <template v-slot:[`item.fechaCreacion`]="{ item }">
                                {{ item.fechaCreacion | fechaISO }}
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <!-- <v-icon color="green" @click="openForm(4, item)"> mdi-eye </v-icon> -->
                                <v-icon color="blue" @click="openForm(5, item)"> mdi-lock </v-icon>
                                <v-icon color="red" @click="openForm(3, item)"> mdi-delete </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card></v-col
            >
        </v-row>
        <v-dialog v-model="openDialog" v-if="openDialog && step != 5" persistent max-width="720px">
            <v-card>
                <v-card-title class="grey lighten-5">
                    <strong>{{ modalText }} USUARIOS</strong>
                </v-card-title>
                <v-container class="pa-3" align="center">
                    <v-row align="center">
                        <v-col cols="12" md="4">
                            <v-select
                                clearable
                                v-model="datos.idTipoDocumento"
                                dense
                                hide-details="auto"
                                outlined
                                :items="tipoDocumento"
                                item-text="text"
                                item-value="id"
                                :error-messages="errors.collect('datos.idTipoDocumento')"
                                label="Tipo de documento*"
                                data-vv-name="datos.idTipoDocumento"
                                data-vv-as="Tipo"
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                v-model="datos.numeroDocumento"
                                dense
                                hide-details="auto"
                                outlined
                                label="Nro Documento*"
                                :error-messages="errors.collect('datos.numeroDocumento')"
                                data-vv-name="datos.numeroDocumento"
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                v-model="datos.nombres"
                                dense
                                outlined
                                hide-details="auto"
                                label="Nombres Completos*"
                                :error-messages="errors.collect('datos.nombres')"
                                data-vv-name="datos.nombres"
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field v-model="datos.telefono" hide-details="auto" dense outlined label="Telefono" />
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                v-validate="'email'"
                                :error-messages="errors.collect('datos.correo')"
                                data-vv-name="datos.correo"
                                v-model="datos.correo"
                                hide-details="auto"
                                dense
                                outlined
                                label="Correo"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-card class="blue lighten-5">
                                <v-card-text>
                                    <v-col cols="12" class="blue lighten-4">
                                        <form @submit.prevent="agregarEstados" data-vv-scope="form-2">
                                            <v-row>
                                                <v-col cols="9">
                                                    <v-autocomplete
                                                        clearable
                                                        v-model="idOrigen"
                                                        v-validate="'required'"
                                                        dense
                                                        hide-details="auto"
                                                        outlined
                                                        :items="origenLista"
                                                        item-text="descripcion"
                                                        item-value="id"
                                                        :error-messages="errors.collect('idOrigen')"
                                                        label="Asignar Origen*"
                                                        data-vv-name="idOrigen"
                                                        data-vv-as="estado"
                                                        return-object
                                                        required
                                                    >
                                                        <template v-slot:item="data">
                                                            <v-chip small color="secondaryTheme" class="caption px-2" dark>{{ data.item.canal }}</v-chip>
                                                            &nbsp;&nbsp;<span class="caption">{{ data.item.descripcion }} </span>
                                                        </template></v-autocomplete
                                                    >
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-btn type="submit">Agregar</v-btn>
                                                </v-col>
                                            </v-row>
                                        </form>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-data-table
                                            item-key="idOrigen"
                                            :headers="headersOrigen"
                                            :items="selectedOrigen"
                                            :items-per-page="5"
                                            class="elevation-1"
                                        >
                                            <template v-slot:[`item.actions`]="{ item }">
                                                <v-icon small color="red" @click="_handlePutUsuOrigen(item, 'delete')"> mdi-delete </v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-select
                                clearable
                                v-model="datos.idRole"
                                v-validate="'required'"
                                dense
                                hide-details="auto"
                                outlined
                                :items="roles"
                                item-text="text"
                                item-value="id"
                                :error-messages="errors.collect('datos.idRole')"
                                label="Asignar Role*"
                                data-vv-name="datos.idRole"
                                data-vv-as="role"
                                required
                            />
                        </v-col>
                        <v-col cols="12" md="6" v-if="!datos.isLogin">
                            <v-text-field
                                dense
                                hide-details="auto"
                                v-model="datos.usuario"
                                v-validate="'required'"
                                append-icon="mdi-account"
                                outlined
                                :error-messages="errors.collect('datos.usuario')"
                                data-vv-name="datos.usuario"
                                placeholder="admin"
                                required
                                name="login"
                                label="USUARIO"
                                type="text"
                        /></v-col>
                        <v-col cols="12">
                            <v-checkbox v-model="datos.isLogin" label="Usuario Login?"></v-checkbox>
                        </v-col>
                        <template v-if="datos.isLogin">
                            <v-col cols="12" sm="8" offset-sm="2">
                                <v-card class="grey lighten-5">
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    v-model="datos.usuario"
                                                    v-validate="'required'"
                                                    prepend-icon="mdi-account"
                                                    outlined
                                                    :error-messages="errors.collect('datos.usuario')"
                                                    data-vv-name="datos.usuario"
                                                    placeholder="admin"
                                                    required
                                                    name="login"
                                                    label="Usuario*"
                                                    type="text"
                                                    dense
                                            /></v-col>
                                            <v-col cols="12">
                                                <v-text-field
                                                    dense
                                                    v-model="datos.newPassword"
                                                    prepend-icon="mdi-lock"
                                                    outlined
                                                    name="password"
                                                    label="Contraseña*"
                                                    placeholder="password"
                                                    type="password"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </template>

                        <v-col cols="12">
                            <small>*Campos Obligatorios</small>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions class="grey lighten-5">
                    <v-spacer />
                    <v-btn color="darken-1" text @click="EMPTY_FORM"> CERRAR </v-btn>
                    <v-btn v-if="step != 4" :loading="loadingBTN" color="primary" @click="fnHandleCrud"> {{ modalText }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="openDialog" v-if="openDialog && step == 5" persistent max-width="720px">
            <v-card>
                <v-card-title class="grey lighten-5">
                    <strong>{{ modalText }} - {{ datos.usuario }}</strong>
                </v-card-title>
                <v-container class="pa-3" align="center">
                    <v-row align="center">
                        <template>
                            <v-col cols="12" sm="8" offset-sm="2">
                                <v-card class="grey lighten-5">
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    v-model="datos.usuario"
                                                    v-validate="'required'"
                                                    prepend-icon="mdi-account"
                                                    outlined
                                                    :error-messages="errors.collect('datos.usuario')"
                                                    data-vv-name="datos.usuario"
                                                    placeholder="admin"
                                                    required
                                                    :readonly="true"
                                                    name="login"
                                                    label="Usuario*"
                                                    dense
                                            /></v-col>
                                            <v-col cols="12">
                                                <v-text-field
                                                    dense
                                                    v-model="datos.password"
                                                    v-validate="'required'"
                                                    prepend-icon="mdi-lock"
                                                    outlined
                                                    required
                                                    name="password"
                                                    label=" Nueva contraseña*"
                                                    placeholder="password"
                                                    type="password"
                                                    :error-messages="errors.collect('datos.password')"
                                                    data-vv-name="datos.password"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </template>
                    </v-row>
                </v-container>
                <v-card-actions class="grey lighten-5">
                    <v-spacer />
                    <v-btn color="darken-1" text @click="EMPTY_FORM"> CERRAR </v-btn>
                    <v-btn v-if="step != 4" :loading="loadingPassword" color="primary" @click="updatedData"> {{ modalText }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Delete :open="openDelete" :loadingBTN="loadingBTN" @handle-delete="fnHandlePut" @closeModal="closeDocumento" />
        <Timer :open="openTimer" :loadingBTN="loadingBTN" @handle-timer="fnHandleTimer" @closeModal="closeDocumento" />
        <Documento :open="openDocumento" @handle-search="fnHandleGet" @closeModal="closeDocumento" />
    </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { exportXLSX } from '@/utils'
import { mixins } from '@/mixins/mixin.js'
import Delete from '@/components/modals/Delete'
import Timer from '@/components/modals/Timer'
import Documento from '@/components/modals/Documento'

const defaultText = 'REGISTRAR'

export default {
    components: {
        Delete,
        Timer,
        Documento
    },
    mixins: [mixins],
    data() {
        return {
            idOrigen: null,
            selectedOrigen: [],
            menu: false,
            fechaFiltro: new Date().toISOString().substr(0, 7),
            openDialog: false,
            typeMessageTimer: 'success',
            alert: false,
            messageTimer: 'Actualizacion de prospectos completa',
            openDelete: false,
            openTimer: false,
            openDocumento: false,
            search: '',
            singleSelect: true,
            loadingPassword: false,
            loadingBTN: false,
            selected: null,
            modalText: defaultText,
            step: 1,
            loading: false,
            roles: [
                { id: 4, text: 'Editor' },
                { id: 6, text: 'SuperAdmin' }
            ],
            headers: [
                { text: 'Actions', value: 'actions', sortable: false },
                { text: 'usuario', value: 'usuario' },
                { text: 'Nombre', value: 'nombres' },
                { text: 'Role', value: 'role' },
                { text: 'telefono', value: 'telefono' },
                { text: 'correo', value: 'correo' },
                { text: 'Login', value: 'isLogin' },
                { text: 'fechaCreacion', value: 'fechaCreacion' }
            ],
            listaOrigen: [],
            headersOrigen: [
                { text: 'Actions', value: 'actions', sortable: false },
                { text: 'Origen', value: 'origen' }
            ]
        }
    },
    watch: {
        step(val) {
            if (val == 1) {
                this.modalText = defaultText
            } else if (val == 2) {
                this.modalText = 'ACTUALIZAR'
            } else if (val == 5) {
                this.modalText = 'ACTUALIZAR CONTRASEÑA'
            } else if (val == 3) {
                this.modalText = 'ELIMINAR'
            } else {
                this.modalText = 'VISUALIZAR'
            }
        }
    },
    computed: {
        ...mapGetters({
            origenLista: 'origen/lista',
            departamentoAssign: 'ubigeo/departamentoAssign'
        }),
        ...mapGetters('usuarios', ['lista']),
        ...mapState('usuarios', ['datos', 'listRoles']),
        ...mapState('utilities', ['tipoDocumento'])
    },
    methods: {
        ...mapMutations({
            RESET_FORM: 'usuarios/RESET_FORM',
            UPDATE_DATOS: 'usuarios/UPDATE_DATOS',
            OPEN_MODAL: 'mainUI/OPEN_MODAL',
            OPEN_BAR: 'mainUI/OPEN_BAR'
        }),
        ...mapActions({
            handleGet: 'usuarios/obtenerLista',
            handlePost: 'usuarios/registrar',
            handleSignUp: 'usuarios/singup',
            handlePut: 'usuarios/actualizar',
            handleGetUsuOrigen: 'usuarios/getUsuarioOrigen',
            handleGetOrigen: 'origen/obtenerLista',
            handlePostUsuOrigen: 'usuarios/registrarUsuarioOrigen',
            handlePutUsuOrigen: 'usuarios/actualizarUsuarioOrigen',
            updatePass: 'usuarios/actualizarDatos'
        }),
        async _handlePutUsuOrigen(item) {

            await this.handlePutUsuOrigen({ ...item, activo: 0 })
            const { payload } = await this.handleGetUsuOrigen({ usuario: item.usuario })
            console.log('here---1', payload)
            const origenes = []
            console.log('origenLista ', this.origenLista)
            payload.map((x) => {
                console.log('findItem ', x.idOrigen)
                const findItem = this.origenLista.find((y) => y.id == x.idOrigen)
                origenes.push({ ...x, origen: `${findItem.descripcion} | ${findItem.canal}` })
            })
            this.selectedOrigen = origenes.sort((a, b) => b.idCanal - a.idCanal)
        },
        EMPTY_FORM() {
            this.selectedOrigen = []
            this.idOrigen = null
            this.openDialog = false
            this.loading = false
            this.loadingBTN = false
            this.menu = false
            this.selected = null
            this.openDelete = false
            this.openTimer = false
            this.openDocumento = false
            this.selectedOrigen = []
            this.OPEN_MODAL({ state: false })
            this.step = 1
            this.RESET_FORM()
        },
        async openForm(tipo, item) {
            this.step = tipo
            let status = false
            this.selected = item
            if (tipo == 1) {
                this.RESET_FORM()
                status = true
            } else if (tipo == 2 || tipo == 4 || tipo == 5) {
                if (!item) {
                    this.OPEN_BAR({ state: true, text: 'No hay selección', color: 'red' })
                } else {
                    status = true
                    this.UPDATE_DATOS(item)
                    //console.log('item ', item)
                    const { payload } = await this.handleGetUsuOrigen({ usuario: item.usuario })
                    //console.log('payload', payload)
                    const origenes = []
                    payload.map((x) => {
                        const findItem = this.origenLista.find((y) => y.id == x.idOrigen)
                        origenes.push({ ...x, origen: `${findItem?.descripcion || ''} | ${findItem?.canal || ''}` })
                    })
                    this.selectedOrigen = origenes
                }
            } else if (tipo == 3) {
                if (!item) {
                    this.OPEN_BAR({ state: true, text: 'No hay selección', color: 'red' })
                } else {
                    this.openDelete = true
                }
            }
            this.openDialog = status
        },
        async updatedData() {
          this.loadingPassword= true
            console.log('datos-pass')
            const data = {
                usuario: this.datos.usuario,
                newPassword: this.datos.password
            }
            await this.updatePass(data)
            this.loadingPassword= false
            this.EMPTY_FORM()
        },

        async fnHandleCrud() {
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    this.loadingBTN = true
                    this.OPEN_MODAL({ state: true })
                    console.log('step', this.step)
                    if (this.step == 1) {
                        this.datos.selectedOrigen = this.selectedOrigen
                        await this.handlePost(this.datos)
                    } else {
                        const item = this.selected
                        const { idRole, idRegion } = this.datos
                        console.log('item', item)
                        console.log('item', {
                            ...item,
                            idRole,
                            optionalRoles: JSON.stringify({ ...JSON.parse(item.optionalRoles), idDepartamento: idRegion }),
                            password: this.datos.newPassword
                        })
                        await this.handlePut({
                            ...item,
                            idRole,
                            optionalRoles: JSON.stringify({ ...JSON.parse(item.optionalRoles), idDepartamento: idRegion }),
                            password: this.datos.newPassword,
                            isPassword: this.datos.newPassword ? true : false
                        })
                    }
                    await this.fnHandleGet({ status: true })
                    this.EMPTY_FORM()
                }
            })
        },
        async fnHandlePut(v) {
            this.loadingBTN = true
            const item = this.selected
            if (this.step == 3) {
                item.activo = 0
            }
            await this.handlePut(item)
            await this.fnHandleGet({ status: true })
            this.EMPTY_FORM()
        },
        async fnHandleTimer(v) {
            this.loadingBTN = true
            const item = this.selected
            if (this.step == 5) {
                item.isTimer = true
                item.isDate = v.dates
            }
            const data = await this.handlePut(item)
            await this.fnHandleGet({ status: true })
            this.EMPTY_FORM()
            console.log('data', data)
            if (data.code == '00') {
                this.messageTimer = data.message
                this.typeMessageTimer = 'error'
            }
            this.alert = !this.alert
            console.log('alert', this.alert)
        },
        async fnHandleGet({ numeroDocumento = null, fechaFiltro = null, all = true, status = false }) {
            this.loading = true
            await this.handleGet({ fechaFiltro, numeroDocumento, all, status })
            this.EMPTY_FORM()
        },
        downloadHandle() {
            this.loading = true
            const headers = [
                'id',
                'idTipoDocumento',
                'tipoDocumento',
                'idRole',
                'role',
                'numeroDocumento',
                'nombres',
                'telefono',
                'correo',
                'usuario',
                'login',
                'fechaCreacion'
            ]

            const filename = `REPORT_USUARIOS_${Date.now()}`
            exportXLSX({ headers, campos: headers, arrayData: this.lista }, { filename })
            this.loading = false
        },
        closeDocumento(nombreModal) {
            this[nombreModal] = false
        },

        async agregarEstados() {
            this.$validator.validateAll('form-2').then(async (result) => {
                if (result) {
                    if (this.step == 1) {
                        this.selectedOrigen.push({ origen: `${this.idOrigen.descripcion} | ${this.idOrigen.canal}` })
                    } else {
                        await this.handlePostUsuOrigen({ usuario: this.datos.usuario, activo: 1, idOrigen: this.idOrigen.id })
                        const { payload } = await this.handleGetUsuOrigen({ usuario: this.datos.usuario })
                        console.log('here---1', payload)
                        const origenes = []
                        console.log('origenLista ', this.origenLista)
                        payload.map((x) => {
                            const findItem = this.origenLista.find((y) => y.id == x.idOrigen)
                            origenes.push({ ...x, origen: `${findItem.descripcion} | ${findItem.canal}` })
                        })
                        this.selectedOrigen = origenes
                    }
                    this.idOrigen = null
                }
            })
        }
    },
    async created() {
        await this.fnHandleGet({ all: true, status: false })
        await this.handleGetOrigen({ status: false })
    }
}
</script>
