<template>
    <v-dialog v-model="open" persistent max-width="500px">
        <v-card>
            <v-card-title>
                <strong>CONFIRMAR TEMPORIZADOR </strong>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col
      cols="12"
      
    >
      <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
clearable 
            v-model="dateRangeText"
           label="Rango de Fecha de los Prospecto "
        prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
        range
          v-model="dates"
        ></v-date-picker>
      </v-menu>
    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="grey lighten-5">
                <v-spacer></v-spacer>
                <v-btn text @click="cerrarModal"> CERRAR </v-btn>
                <v-btn class="primary" text @click="handleAction" :loading="loadingBTN"> CONFIRMAR </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    $_veeValidate: {
        validator: 'new'
    },
    props: ['open','loadingBTN'],
    data() {
        return {
            dates:[],
            menu2: false,

        }
    },
    computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
    methods: {
        async handleAction() {
                    await this.$emit('handle-timer',{dates:this.dates})
        },
        async cerrarModal() {
            await this.$emit('closeModal', 'openTimer')
        }
    },
    async created() {}
}
</script>
